import { render, staticRenderFns } from "./CookiePopup.vue?vue&type=template&id=0ff8794d&"
import script from "./CookiePopup.vue?vue&type=script&lang=ts&"
export * from "./CookiePopup.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButton: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/112/a/NuxtApp/components/base/Button.vue').default})
